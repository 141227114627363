.ui-tabs-scrollable {
  position: relative; }
  .ui-tabs-scrollable > .spacer:not(.hidden-buttons) {
    margin-left: 25px;
    margin-right: 25px; }
    .ui-tabs-scrollable > .spacer:not(.hidden-buttons) > div:first-child > .nav-tabs > li:first-child.active a, .ui-tabs-scrollable > .spacer:not(.hidden-buttons) > div:first-child > .nav-tabs > li:first-child a:hover {
      border-top-left-radius: 0;
      border-left: 1px solid transparent; }
    .ui-tabs-scrollable > .spacer:not(.hidden-buttons) > div:first-child > .nav-tabs > li:last-child.active a, .ui-tabs-scrollable > .spacer:not(.hidden-buttons) > div:first-child > .nav-tabs > li:last-child a, .ui-tabs-scrollable > .spacer:not(.hidden-buttons) > div:first-child > .nav-tabs > li:last-child a:hover {
      border-top-right-radius: 0;
      border-right: 1px solid transparent;
      margin-right: -1px; }
    .ui-tabs-scrollable > .spacer:not(.hidden-buttons) > div:first-child > .tab-content {
      margin-left: -25px;
      margin-right: -25px; }
  .ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs {
    white-space: nowrap;
    overflow: hidden;
    border-bottom: 0; }
    .ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs > li {
      float: none;
      display: table-cell; }
      .ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs > li > a {
        height: 42px; }
  .ui-tabs-scrollable > .spacer > div:first-child > .tab-content {
    margin-top: -1px;
    border-top: 1px solid #ddd; }
  .ui-tabs-scrollable > .nav-button {
    position: absolute;
    width: 25px;
    height: 42px;
    line-height: 42px;
    top: 0;
    color: #428bca;
    cursor: pointer;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 0;
    padding: 0;
    background-color: #fff;
    z-index: 10; }
    .ui-tabs-scrollable > .nav-button:hover {
      background-color: #eee; }
    .ui-tabs-scrollable > .nav-button.left-nav-button {
      left: 0;
      border-top-left-radius: 4px; }
      .ui-tabs-scrollable > .nav-button.left-nav-button:before {
        font-family: 'Glyphicons Halflings';
        content: '\e079'; }
    .ui-tabs-scrollable > .nav-button.right-nav-button {
      right: 0;
      border-top-right-radius: 4px; }
      .ui-tabs-scrollable > .nav-button.right-nav-button:before {
        font-family: 'Glyphicons Halflings';
        content: '\e080'; }
  .ui-tabs-scrollable > .dropdown {
    position: absolute;
    top: 0;
    right: 0; }
    .ui-tabs-scrollable > .dropdown > .dropdown-toggle {
      width: 25px;
      height: 42px;
      line-height: 42px;
      color: #428bca;
      text-align: center;
      border: 1px solid #ddd;
      border-left-width: 0;
      padding: 0;
      background-color: #fff;
      z-index: 10;
      border-radius: 0 4px 0 0; }
      .ui-tabs-scrollable > .dropdown > .dropdown-toggle:before {
        font-family: 'Glyphicons Halflings';
        content: '\e114'; }
  .ui-tabs-scrollable.show-drop-down > .spacer:not(.hidden-buttons) {
    margin-right: calc(25px + 25px); }
    .ui-tabs-scrollable.show-drop-down > .spacer:not(.hidden-buttons) > div:first-child > .tab-content {
      margin-right: calc(-25px - 25px); }
  .ui-tabs-scrollable.show-drop-down > .nav-button.right-nav-button {
    right: 25px;
    border-radius: 0; }

.dropdown-menu > li > a .dropDownTabActiveMark:before {
  font-family: 'Glyphicons Halflings';
  content: '\e013';
  padding-right: 10px;
  font-size: 12px; }

/*make the tabs content be height 100%*/
.ui-tabs-scrollable > .spacer {
  height: 100%; }
  .ui-tabs-scrollable > .spacer > div:first-child {
    height: 100%; }
    .ui-tabs-scrollable > .spacer > div:first-child > .tab-content {
      height: 100%; }
      .ui-tabs-scrollable > .spacer > div:first-child > .tab-content > .active {
        height: 100%;
        overflow: auto; }
